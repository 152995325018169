<template>
	<ion-app>
		<ion-router-outlet />

		<!-- Side menu -->
		<ion-menu
			side="end"
			type="overlay"
			ref="menu"
			content-id="menu-content"
			swipe-gesture="false"
			v-if="showSideMenu"
		>
			<ion-header>
				<ion-toolbar :color="online ? 'branding' : 'dark'">
					<ion-title style="padding: 0px 40px">
						<ion-icon :icon="personicon"></ion-icon>
						{{ user.name }}
					</ion-title>
				</ion-toolbar>
			</ion-header>

			<ion-content id="menu-content">
				<ion-list style="pointer-events: auto" @click="closeMenu">
					<!-- Create Note -->
					<ion-item
						button
						@click="showNotePopup"
						v-if="permissions['edit_any_field_note'] || permissions['create_field_notes']"
					>
						<ion-icon :icon="createicon" slot="start"></ion-icon>
						<ion-label>{{ $t("note.create-note") }}</ion-label>
					</ion-item>

					<!-- Create task request -->
					<ion-item button @click="showTaskRequestPopup" v-if="permissions['create_task_request']">
						<ion-icon :icon="clipboardicon" slot="start"></ion-icon>
						<ion-label>{{ $t("task.task-request") }}</ion-label>
					</ion-item>

					<!-- Tasks -->
					<ion-item
						button
						:disable="$router.currentRoute.value.name?.toString().startsWith('tasks.')"
						:color="$router.currentRoute.value.name?.toString().startsWith('tasks.') ? 'primary' : ''"
						@click="$router.push({ name: 'tasks.list' })"
					>
						<ion-icon :icon="checkboxicon" slot="start"></ion-icon>
						<ion-label>{{ $tc("task.task", 2) }}</ion-label>
						<ion-badge color="danger" style="padding: 4px 6px" v-if="tasksDue.length">
							{{ tasksDue.length }}
						</ion-badge>
					</ion-item>

					<!-- Harvest Overview -->
					<ion-item
						button
						:color="$router.currentRoute.value.name === 'harvest-overview' ? 'primary' : ''"
						@click="$router.push({ name: 'harvest-overview' })"
						v-if="permissions['view_mobile_harvest_overview']"
					>
						<ion-icon :icon="leaficon" slot="start"></ion-icon>
						<ion-label>{{ $t("harvest-overview.harvest-overview") }}</ion-label>
					</ion-item>

					<!-- Harvest Schedule -->
					<ion-item
						button
						:color="$router.currentRoute.value.name === 'harvest-schedule' ? 'primary' : ''"
						@click="$router.push({ name: 'harvest-schedule' })"
						v-if="permissions['view_mobile_harvest']"
					>
						<ion-icon :icon="calendaricon" slot="start"></ion-icon>
						<ion-label>{{ $t("harvest-schedule.harvest-schedule") }}</ion-label>
					</ion-item>

					<!-- Lot Search -->
					<ion-item
						button
						:color="$router.currentRoute.value.name === 'lot-search' ? 'primary' : ''"
						@click="$router.push({ name: 'lot-search' })"
						v-if="permissions['view_mobile_lots']"
					>
						<ion-icon :icon="searchicon" slot="start"></ion-icon>
						<ion-label>{{ $t("lot-search.lot-search") }}</ion-label>
					</ion-item>

					<!-- Irrigation -->
					<ion-item
						button
						:color="$router.currentRoute.value.name === 'irrigation' ? 'primary' : ''"
						@click="$router.push({ name: 'irrigation' })"
						v-if="permissions['view_irrigation_schedules'] || permissions['set_irrigation_schedules']"
					>
						<ion-icon :icon="watericon" slot="start"></ion-icon>
						<ion-label>{{ $t("task.irrigation") }}</ion-label>
					</ion-item>

					<!-- Alerts -->
					<ion-item
						button
						:color="$router.currentRoute.value.name === 'alerts' ? 'primary' : ''"
						@click="$router.push({ name: 'alerts' })"
					>
						<ion-icon :icon="notificationsicon" slot="start"></ion-icon>
						<ion-label>{{ $tc("common.alert", 2) }}</ion-label>
						<ion-badge color="danger" style="padding: 4px 6px" v-if="alerts.length">
							{{ alerts.length }}
						</ion-badge>
					</ion-item>

					<!-- Register Device -->
					<ion-item v-if="!deviceRegistered" button @click="registerDevice">
						<ion-icon :icon="fingerprinticon" slot="start"></ion-icon>
						<ion-label>{{ $t("menu.register-device") }}</ion-label>
					</ion-item>

					<!-- Desktop Switch -->
					<!-- FIXME: Why is this here, when the desktopRedirect method doesn't exist? -->
					<!-- <ion-item v-if="desktopSwitchEnabled" button @click="desktopRedirect">
						<ion-icon :icon="desktopicon" slot="start"></ion-icon>
						<ion-label>{{ $t("menu.visit-desktop") }}</ion-label>
					</ion-item> -->

					<!-- Logout -->
					<ion-item button @click="logout">
						<ion-icon :icon="logouticon" slot="start"></ion-icon>
						<ion-label>{{ $t("menu.logout") }}</ion-label>
					</ion-item>
				</ion-list>
			</ion-content>

			<ion-footer>
				<!-- Install -->
				<ion-item detail color="success" v-if="!isPWA" @click="showInstallPossibleModal">
					<ion-icon :icon="icons.downloadOutline" slot="start"></ion-icon>
					<ion-label>{{ $t("pwa.install-app") }}</ion-label>
				</ion-item>
				<!-- Language -->
				<ion-item>
					<ion-icon :icon="icons.earth" slot="start"></ion-icon>
					<ion-label>{{ $t("menu.language") }}:</ion-label>
					<ion-select :value="locale" @ionChange="_setLocale($event.target.value)" v-i18n>
						<ion-select-option
							v-for="locale in $i18n.availableLocales"
							:key="`locale-${locale}`"
							:value="locale"
							>{{ locale.toUpperCase() }}</ion-select-option
						>
					</ion-select>
				</ion-item>
			</ion-footer>
		</ion-menu>
	</ion-app>
</template>

<script lang="ts">
	import * as webauthn from "@/lib/webauthn";
	import {
		IonApp,
		IonRouterOutlet,
		IonContent,
		IonHeader,
		IonFooter,
		IonToolbar,
		IonTitle,
		IonIcon,
		IonLabel,
		IonItem,
		IonBadge,
		IonList,
		IonMenu,
		IonSelect,
		IonSelectOption,
		loadingController,
	} from "@ionic/vue";
	import { defineComponent } from "vue";
	import {
		create as createicon,
		clipboard as clipboardicon,
		checkbox as checkboxicon,
		fingerPrint as fingerprinticon,
		leaf as leaficon,
		calendar as calendaricon,
		search as searchicon,
		water as watericon,
		notifications as notificationsicon,
		desktop as desktopicon,
		logOut as logouticon,
		person as personicon,
	} from "ionicons/icons";
	import * as icons from "ionicons/icons";
	import { mapState } from "vuex";
	import { sendMessage } from "@/components/send-message";
	import { logout } from "@/api/logout";
	import userApi from "@/api/user";
	import { showAddEditNote as showAddNote } from "@/views/Modal/AddEditNote.vue";
	import { showAddEditTaskRequest } from "@/views/Modal/AddEditTaskRequest.vue";
	import { isPWA } from "@/lib/pwa";
	import showInstallPossibleModal from "@/components/install/InstallPossibleModal";
	import { Task } from "seedgreen-shared/models/Task";

	// Forcibly clear the refresh token from the old prod domain setup
	document.cookie = "refresh-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.seedgreen.gva.ai";

	export default defineComponent({
		name: "App",
		data: function () {
			return {
				createicon,
				clipboardicon,
				checkboxicon,
				fingerprinticon,
				leaficon,
				calendaricon,
				searchicon,
				watericon,
				notificationsicon,
				desktopicon,
				logouticon,
				personicon,
				icons,
				isPWA,
			};
		},
		components: {
			IonApp,
			IonRouterOutlet,
			IonContent,
			IonHeader,
			IonFooter,
			IonToolbar,
			IonTitle,
			IonIcon,
			IonLabel,
			IonItem,
			IonBadge,
			IonList,
			IonMenu,
			IonSelect,
			IonSelectOption,
		},
		props: {
			title: { type: String, default: "" },
			showBackButton: { type: Boolean, default: true },
			loginRequired: { type: Boolean, default: true },
			showSideMenu: { type: Boolean, default: true },
			refreshCallback: { type: Function, default: null },
		},
		mounted() {
			this.$i18n.locale = this.locale;
		},
		methods: {
			logout,
			_setLocale(locale: string) {
				userApi.setLocale(locale);
			},
			closeMenu() {
				(this.$refs.menu as InstanceType<typeof IonMenu>).$el.close();
			},
			showNotePopup() {
				showAddNote(this);
			},
			showTaskRequestPopup() {
				showAddEditTaskRequest(this);
			},
			async registerDevice() {
				let loading = await loadingController.create({ message: this.$t("menu.registering-device") });
				loading.present();
				try {
					let attestationOptions = await webauthn.getAttestationOptions();
					let credentialOptions = webauthn.credentialCreationOptions(attestationOptions);
					let credentials = await webauthn.createCredentials(credentialOptions);
					let attestation = webauthn.attestationRequest(credentials);
					let attestationResponse = await webauthn.attestIdentity(attestation);
					if (attestationResponse.data.status == "ok") {
						sendMessage(this.$t("menu.device-registered"), 5000);
					} else throw "Failed to register device";
				} catch (e) {
					console.error("Failed to register device", e);
					sendMessage(this.$t("menu.failed-register"), 5000, null, "danger");
				} finally {
					loading.dismiss();
				}
			},
			async showInstallPossibleModal() {
				this.closeMenu();
				await showInstallPossibleModal(false);
			},
		},
		computed: {
			...mapState([
				"deviceRegistered",
				"permissions",
				"online",
				"offlineQueue",
				"user",
				"alerts",
				"currentLocation",
				"customerUnits",
				"noteCategories",
				"observationSubTypes",
				"observationScalesById",
				"groupedObservationSubTypes",
				"observationTypesById",
				"observationCategoriesById",
				"needsToSignAgreement",
				"customerPreferences",
				"tasks",
				"locale",
			]),
			tasksDue() {
				return this.tasks.filter(
					(task: Task) => !task.completedDate && new Date(task.displayDate) <= new Date(),
				);
			},
			desktopSwitchEnabled() {
				// if we decide to disable this for smaller screens, it can be done here.
				// if (screen.width < 500) return false;
				return this.customerPreferences.sitewide?.showMobileSwitch || false;
			},
			hasHeaderToolbar() {
				return !!this.$slots["header-toolbar"];
			},
		},
		watch: {
			locale(value) {
				this.$i18n.locale = value;
			},
		},
	});
</script>
