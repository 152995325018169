<template>
	<!-- Page -->
	<ion-page>
		<ion-header v-if="authorized">
			<ion-toolbar :color="online ? 'branding' : 'dark'">
				<ion-title>{{ title }}</ion-title>

				<!-- Back button -->
				<ion-buttons slot="start" v-if="showBackButton">
					<ion-back-button default-href="/" :text="$t('common.back')" />
				</ion-buttons>

				<ion-buttons slot="start">
					<slot name="header-left-button"></slot>
				</ion-buttons>

				<!-- Side menu button -->
				<ion-buttons slot="end" v-if="showSideMenu">
					<ion-menu-button menu="end"></ion-menu-button>
					<ion-badge
						color="danger"
						style="
							pointer-events: none;
							padding: 4px 6px;
							left: calc(50% + 6px);
							top: 8px;
							min-width: 12px;
							font-size: 8px;
							font-weight: 400;
							border-radius: 8px;
							position: absolute;
							z-index: 1;
						"
						v-if="alerts.length || tasksDue.length"
					>
						{{ alerts.length + tasksDue.length }}
					</ion-badge>
				</ion-buttons>

				<!-- Offline queue size -->
				<!--<ion-badge color="danger"
                v-if="loggedIn && offlineQueue.length"
                slot="end"
                style="padding: 8px 12px; margin-right: 16px;">{{ offlineQueue.length }}</ion-badge>-->
			</ion-toolbar>

			<!-- Header toolbar -->
			<ion-toolbar :color="online ? 'branding' : 'dark'" v-if="hasHeaderToolbar">
				<slot name="header-toolbar"></slot>
			</ion-toolbar>
		</ion-header>

		<ion-content v-if="authorized">
			<!-- Refresher -->
			<ion-refresher
				slot="fixed"
				pull-factor="1"
				pull-min="50"
				pull-max="150"
				v-if="refreshCallback"
				@ionRefresh="refreshCallback"
			>
				<ion-refresher-content></ion-refresher-content>
			</ion-refresher>

			<!-- Page-specific content -->
			<slot />
		</ion-content>

		<ion-footer style="background: white">
			<slot name="footer"></slot>
		</ion-footer>
	</ion-page>
</template>

<script>
	import {
		IonContent,
		IonHeader,
		IonFooter,
		IonToolbar,
		IonTitle,
		IonBadge,
		IonBackButton,
		IonButtons,
		IonMenuButton,
		IonRefresherContent,
		IonRefresher,
		IonPage,
	} from "@ionic/vue";
	import { mapState } from "vuex";

	export default {
		name: "BasePage",
		components: {
			IonContent,
			IonHeader,
			IonFooter,
			IonToolbar,
			IonTitle,
			IonBadge,
			IonBackButton,
			IonButtons,
			IonMenuButton,
			IonRefresherContent,
			IonRefresher,
			IonPage,
		},
		props: {
			title: { type: String, default: "" },
			showBackButton: { type: Boolean, default: true },
			loginRequired: { type: Boolean, default: true },
			showSideMenu: { type: Boolean, default: true },
			refreshCallback: { type: Function, default: null },
		},
		created() {
			// if the mobile app was requested, disable allowDesktop.
			document.cookie = "allowDesktop=false";

			// Redirect to login?
			if (!this.authorized) {
				this.$router.replace({ name: "login" });
			}
			// Redirect to user agreement?
			else if (
				this.needsToSignAgreement &&
				this.$router.currentRoute.value.path != "/user-agreement" &&
				this.loggedIn
			) {
				this.$router.replace({
					name: "user-agreement",
					query: {
						returnUrl: this.$router.currentRoute.value.fullPath,
					},
				});
			}
		},
		methods: {
			desktopRedirect() {
				document.cookie = "allowDesktop=true";
				window.location = "/Schedule";
			},
		},
		computed: {
			...mapState([
				"permissions",
				"loggedIn",
				"online",
				"offlineQueue",
				"user",
				"alerts",
				"currentLocation",
				"customerUnits",
				"customerPreferences",
				"noteCategories",
				"observationSubTypes",
				"observationScalesById",
				"groupedObservationSubTypes",
				"observationTypesById",
				"observationCategoriesById",
				"needsToSignAgreement",
				"tasks",
			]),
			tasksDue() {
				return this.tasks.filter(
					(task) =>
						!task.completedDate &&
						new Date(task.plannedDate) <= new Date() &&
						task.assigneeUserId === this.user.id,
				);
			},
			authorized() {
				return !this.loginRequired || this.loggedIn;
			},
			hasHeaderToolbar() {
				return !!this.$slots["header-toolbar"];
			},
		},
	};
</script>
